html {
  scroll-behavior: smooth;
}
.hidden {
  display: none;
}
ul.nav-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
}
ul li.nav-menu-item {
  padding: 0.5em 1em;
}
.w-100 {
  width: 100%;
}
.w-80 {
  width: 80%;
}
.w-75 {
  width: 75%;
}
.w-60 {
  width: 60%;
}
.w-50 {
  width: 50%;
}
.w-40 {
  width: 40%;
}
.w-25 {
  width: 25%;
}
.w-20 {
  width: 20%;
}
.max-w-100 {
  max-width: 100%;
}
.notification {
  color: white;
  text-decoration: none;
  position: relative;
  display: inline-block;
  margin-top: 24px;
}

.notification:hover {
  cursor: pointer;
}
.padding-mini,
.margin-mini {
  padding: 5px;
}
.notification-area {
  border-radius: 10px;
  top: -20px;
  left: 0px;
  margin: 0.5em;
  padding: 0.5em;
  width: calc(100% - 2em);
  position: relative;
  background-color: white;
  color: black;
  height: auto;
  max-height: 80vh;
  overflow-y: scroll;
}
.notification-item {
  padding: 0.2em 0.5em;
  font-size: 0.8em;
  border-bottom: 1px solid grey;
  cursor: pointer;
}
.notification-item:hover {
  background-color: #ff8f00;
}
.notification-item #date {
  font-size: 0.75em;
}
.notification .badge {
  position: absolute;
  top: -5px;
  right: -7px;
  padding: 2.5px 5.5px;
  border-radius: 50%;
  background: #880e4f;
  color: white;
  font-size: 12px;
}
.avatar {
  margin: 1em;
  border: 0.5px solid white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.tray {
  padding: 16px;
}
.list-item:hover {
  background-color: #f7b45c;
}
.list-item,
.list-item-head {
  cursor: pointer;
}
.list-item-head {
  text-align: left;
}
table {
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
}
tr {
  margin: -2px;
  padding: 0.2em;
}
tr.list-item-head {
  padding: 0.5em 0.2em;
}
td {
  width: 25%;
  padding-top: 0.5em;
  overflow-x: wrap;
  font-size: 0.8rem;
  margin: 0 0.5em;
}
/* .content-list {
  margin: 0.5em auto;
} */
.tags {
  border-radius: 5px;
  min-width: 240px;
  max-width: 300;
  height: calc(60px + 2em);
  margin: 1em;
  padding: 1em 1em;
}
.tags i,
span {
  text-align: center;
}
.tags i {
  font-size: 64px;
}
.list-item span,
.list-item-head span {
  /* width: 250px; */
  flex: 1 1 0px;
  padding: 0.5em 1em;
  text-align: left;
  /* border: 1px solid red; */
}
.list-item span.sn,
.list-item-head span.sn {
  width: 20px;
}
.list-item span.action,
.list-item-head span.action {
  flex-grow: 1;
  /* width: 250px;
  padding: 0.5em; */
  text-align: right;
  /* border: 1px solid red; */
}
span.action i {
  font-size: 1.5em;
}
.list-item-head {
  font-weight: bold;
}
.success {
  color: green;
  background-color: rgb(181, 243, 181);
  border: 1px solid green;
}
.fail {
  color: red;
  border: 1px solid red;
  background-color: rgb(235, 189, 189);
}
.feeback {
  width: 50%;
  padding: 0.25em 0.5em;
  z-index: 10000;
  border-radius: 15px;
  /* position: fixed; */
  /* top: 5em; */
  margin: 2em auto;
}
.btn-error {
  background-color: red;
  color: white;
}
.error-text {
  color: red;
}
.text-action{
  color: dark blue !important;
  cursor: pointer;
}
.text-action:hover{text-decoration: underline;}
.text-success {
  color: green;
}
.error {
  border: 1px solid white;
  padding: 0.5em 1em;
  border-radius: 5px;
  margin-bottom: 2em;
  text-align: center;
}
.red-border {
  border: 1px solid red;
}
.bg-color-accent {
  background-color: #880e4f;
  color: white;
}
.bg-color-main {
  background-color: #ff8f00;
  color: white;
}
.bg-color-grey {
  background-color: grey;
  color: black;
}
.bg-color-main-dark {
  background-color: #e64a19;
  color: white;
}
.bg-color-white-dark {
  background-color: white;
  color: black;
}
.text-white {
  color: white;
}
.text-grey {
  color: #ccc;
}
.text-color-main {
  color: #ff8f00;
}
.text-color-main-dark {
  color: #e64a19;
}
.text-color-accent {
  color: #880e4f;
}
.shadow {
  box-shadow: 5px 5px 10px 2px rgba(124, 123, 123, 0.623);
}
body {
  color: black;
  /* width: 100%; */
  font-family: "Fira Sans Condensed", sans-serif;
  height: 100vh;
  overflow-x: hidden;
}
form {
  align-items: center;
  width: calc(100% - 4em);
  padding: 0;
  margin-top: 1em;
}

form h1 {
  margin-top: 0;
}
form h1,
form h2 {
  padding: 0.5em 0;
}
.form-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: flex-start; */
  width: calc(100% - 2em);
  padding: 1em;
}
.form-group label {
  text-align: left;
}
.btn {
  cursor: pointer;
  font-size: 1.2em;
}
.btn-normal,
.btn-reject,
.btn-success {
  border-radius: 5px;
  padding: 8px 16px;
  width: 200px;
}
.btn-normal {
  background-color: #880e4f;
  color: white;
}
.btn-reject {
  background-color: red;
  color: white;
}
.btn-success {
  background-color: green;
  color: white;
}

.form-group a:link,
.form-group a:visited {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.form-group a:hover {
  text-decoration: underline;
}
.form-group input[type="checkbox"]:checked {
  background-color: #880e4f;
  color: #880e4f;
}
.form-group input[type="checkbox"] {
  height: 18px;
  width: 18px;
  padding: 0.5em;
}
.form-group input[type="submit"] {
  /* background-color: white; */
  /* border-radius: 5px; */
  width: 140px;
  /* padding: 16px 24px; */
  font-size: 1.2em;
}
.flex-row input[type="search"] {
  line-height: 0.1em;
  outline: none;
  border: 0;
  /* background-color: red; */
}
.form-group input[type="text"]:focus,
.form-group input[type="password"]:focus,
.form-group input[type="email"]:focus,
input[type="search"]:focus {
  outline: none;
}
/* canvas for chart  */
canvas {
  width: 400px;
  height: 400px;
}
.overlay {
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  z-index: 999999999;
  height: 100%;
  background-color: #3512248e;
  overflow-y: auto;
}
.flex-row {
  display: flex;
  flex-direction: row;
  /* width: 100%; */
}
.wrap {
  flex-wrap: wrap;
}
.flex-top {
  align-items: flex-start;
}
.flex-middle {
  align-items: center;
}

.flex-bottom {
  align-items: flex-end;
}
.flex-center {
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}
.flex-start {
  justify-content: flex-start;
}
.flex-space {
  justify-content: space-between;
}
.flex-even {
  justify-content: space-evenly;
}
.border-all-main-dark {
  border: 1px solid #e64a19;
}
.border-all-main {
  border: 1px solid #ff8f00;
}
.border-all-accent {
  border: 1px solid #880e4f;
}
header {
  height: 60px;
  padding: 2em;
  width: calc(100% - 4em);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
}
header .branding {
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 2rem;
}
header #menu {
  cursor: pointer;
}
nav#top {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
nav#top a,
nav#top a:visited {
  color: white;
  margin-left: 1em;
  text-decoration: none;
}
nav#top a:hover {
  text-decoration: underline;
}
main {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: scroll;
  /* border: 1px solid blue; */
}
main .content, .content-list {
  text-align: center;
  margin-bottom: 1em;
  margin: 0.5em auto;
  width:calc(100% - 1em);
}
#right-pane{
  margin-left: 20%;
  position: relative;
}
main .side-pane {
  
  min-height: 100vh;
  width: 20%;
  height: 100%;
}
.setting-section {
  text-align: left;
  margin: 0.5em;
  /* padding: 0 0.5em 0.5em 0.5em; */
}
.setting-section h4 {
  padding: 0.5em;
  /* margin: 0 0 0.5em -0.5em; */
  width: 100%;
}
.side-pane{
  position:fixed;
  top:0;left:0;bottom:0
}
main .side-pane nav#side-nav {
  margin: 0 auto;
  padding: 0.25em;
  width: calc(100% - 0.5em);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid white; */
  overflow: hidden;
  
}
main .side-pane nav#side-nav div.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.25em 0.25em;
  width: calc(100% - 4em);
  margin: 0.25em auto;
  text-transform: uppercase;
  /* font-size: medium; */
  cursor: pointer;
}
@media only screen and (max-width: 1280px) {
  main .side-pane nav#side-nav div.menu-item {
    width: 80px;
  }
  main .side-pane nav#side-nav div.menu-item span {
    display: none;
  }
  main .side-pane nav#side-nav div.menu-item i.material-icons {
    font-size: 4rem;
  }
}
.vehicle-space {
  margin-right: -1em;
}
div.space-left {
  margin-left: 3em;
}
.collapsed {
  animation: shrink 0.5s linear forwards;
}
.expanded {
  animation: expand 0.5s linear forwards;
}

.left-col,
.right-col {
  width: 50%;
  min-height: 100vh;
  height: calc(100% - 2em);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.left-col img.logo-large {
  width: 240px;
}
.logo {
  width: 160px;
  display: none;
}
#heading {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5em 0;
}
#heading #title {
  /* font-size: larger; */
}
/* buttons
 */
button {
  border-radius: 5px;
  padding: 0.5em 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 1em; */
}

.actions{
/* .content-list { */
  margin: 0.25em auto;
  width: calc(100% - 0.5em);

  align-items: center;
  /* border-bottom: 1px solid orangered; */
}
/* .content-list {
  margin-top: 1em;
} */
.center-self {
  margin: 0 auto;
}
.not-found {
  background-image: url("images/paris.jpg");
  background-size: cover;
  width: 100%;
  display: "flex";
  flex-direction: column;
  align-items: "center";
  justify-content: "center";
  height: 100vh;
}
.not-found h1 {
  font-size: 3rem;
  color: red;
}
#searchButton {
  cursor: pointer;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(iconfont/MaterialIcons-Regular.woff2) format("woff2"),
    url(iconfont/MaterialIcons-Regular.woff) format("woff"),
    url(iconfont/MaterialIcons-Regular.ttf) format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /*Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}
.material-icons {
  cursor: pointer;
}
.material-icons {
  font-size: 2em;
  /* padding-right: 1em; */
}
.notification i.material-icons {
  padding-right: 0;
}
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* loader/spinner */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #e36f1e;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
.spinner{
  display: block;
  border-top: 2px solid #e36f1e;
  border-right: 2px solid #880e4f;
  animation: spin 0.5s linear 0s infinite forward;
}
@keyframes spin {
  0%{
    transform: rotate(0d);
  }
  100%{
    transform: rotate(360d);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* animations */
@keyframes shrink {
  from {
    width: 20%;
  }
  to {
    width: 8%;
  }
}

@keyframes expand {
  from {
    width: 8%;
  }
  to {
    width: 20%;
  }
}
